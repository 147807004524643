import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src045276070/src/variametrix-site-2021/src/templates/benefit.js";
export const query = graphql`
  {
    icons: file(name: { eq: "iconset--reporting" }) {
      publicURL
    }
    banner: file(name: { eq: "banner--polished-reports" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64 
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const BenefitBlurb = makeShortcode("BenefitBlurb");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <BenefitBlurb title="Merge-able data" icon={props.data.icons.publicURL} iconOffset={0} mdxType="BenefitBlurb">
  <p>
    Most dashboard solutions will show you vendor charts side-by-side to save
    you from logging in to everything. Since VariaMetrix imports your data we
    can combine from everywhere and aggregate data. View all of your paid clicks
    or impressions compared to all email traffic from various platforms. And
    with our total data ownership policy the data is always yours and will never
    be used outside of your account.
  </p>
    </BenefitBlurb>
    <BenefitBlurb title="Instant presentation" icon={props.data.icons.publicURL} iconOffset={1} mdxType="BenefitBlurb">
  <p>
    Present to clients, executives, or other stakeholders with ease. VariaMetrix
    exports any dashboard directly to PowerPoint with publication-quality charts
    using live data instead of images, making it easy to perform edits or apply
    your own company’s PowerPoint theme.
  </p>
    </BenefitBlurb>
    <BenefitBlurb title="Built for teams" icon={props.data.icons.publicURL} iconOffset={2} mdxType="BenefitBlurb">
  <p>
    VariaMetrix dashboards can be personalized for individual use or built for
    the entire team.
  </p>
    </BenefitBlurb>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      